import { createSuffixedName } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { EVENTS } from '../../../constants/bi'
import _ from 'lodash'
import { withBi } from '../decorators'
import { findPlugin } from '../plugins/utils'
import {
  FormPlugin,
  FieldCollectionType,
  SUBMISSION_DISPLAY_FIELD,
  PAYMENT_DISPLAY_FIELD,
  TITLE_FIELD,
} from '@wix/forms-common'
import {
  updateSchema,
  createSchema,
  setDisplayName,
  setDisplayField,
  removeSchema,
  addField,
  updateField,
  markFieldDeleted,
  setPermissions,
  PRESETS,
  PERMISSIONS_BY_PRESET,
} from '@wix/wix-code-collections-api'
import { FedopsLogger } from '@wix/fedops-logger'
import Experiments from '@wix/wix-experiments'
import { Schema } from '@wix/wix-code-collections-api/lib/types'
import { FormPreset } from '../../../constants/form-types'
import { filterFieldsToCollection, retryOnConcurrentEditingErrors } from './utils'

const WIXCODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762'

export type FieldDataForCollectionActions = Pick<
  FormField,
  'role' | 'fieldType' | 'crmLabel' | 'collectionFieldKey' | 'collectionFieldType' | 'componentRef'
>

export default class CollectionsApi {
  private fedopsLogger: FedopsLogger
  private collectionsApi: any
  private boundEditorSDK: BoundEditorSDK
  private appDefinitionId: any
  private biLogger: any
  private experiments: Experiments

  constructor(
    boundEditorSDK: any,
    collectionsApi: any,
    appDefinitionId: any,
    { biLogger, fedopsLogger, experiments },
  ) {
    this.collectionsApi = collectionsApi
    this.boundEditorSDK = boundEditorSDK
    this.appDefinitionId = appDefinitionId
    this.biLogger = biLogger
    this.fedopsLogger = fedopsLogger
    this.experiments = experiments
  }

  public async removeCollection(collectionId: string): Promise<void> {
    return this.collectionsApi.execute(removeSchema(collectionId))
  }

  private _installWixCode() {
    if (this._isWixCodeInstalled()) {
      return Promise.resolve()
    }

    return this.boundEditorSDK.document.application.install({
      appDefinitionId: WIXCODE_APP_DEF_ID,
      originInfo: null,
    })
  }

  private _isWixCodeInstalled() {
    try {
      const wixCodeApi = this.boundEditorSDK.document.application.getPublicAPI({
        appDefinitionId: 'wix-code',
      })
      return !!wixCodeApi
    } catch (ex) {
      return false
    }
  }

  @retryOnConcurrentEditingErrors()
  public async updateCollectionName(collectionId: string, formName: string): Promise<void> {
    if (await this.isCollectionExists(collectionId)) {
      await this.collectionsApi.execute(
        updateSchema(collectionId, setDisplayName(formName || collectionId)),
      )
    }
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  @retryOnConcurrentEditingErrors()
  public async createCollection(
    {
      preset,
      fields,
      plugins,
      fieldKeyCallback,
      formName,
    }: {
      preset: FormPreset
      fields: FormField[]
      plugins: ComponentPlugin[]
      fieldKeyCallback: (component: ComponentRef, fieldKey: string) => void
      formName: string
    },
    _biData = {},
  ) {
    await this._installWixCode()

    const collections = await this.collectionsApi.getAll()
    const collectionId = createSuffixedName(_.map(collections, 'id'), _.camelCase(preset), '')

    const formFields = this._addFieldsToCollection(fields, fieldKeyCallback)
    const submissionTimeField = {
      displayName: translations.t(`addForm.submissions.${SUBMISSION_DISPLAY_FIELD}`),
      type: FieldCollectionType.DATETIME,
    }
    const paymentField = {
      displayName: translations.t(`addForm.submissions.${PAYMENT_DISPLAY_FIELD}`),
      type: FieldCollectionType.TEXT,
    }
    const paymentPlugin: ComponentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
    const fieldsToAdd = [
      addField(SUBMISSION_DISPLAY_FIELD, submissionTimeField),
      ...formFields,
      paymentPlugin && paymentPlugin.payload ? addField(PAYMENT_DISPLAY_FIELD, paymentField) : null,
    ].filter((field) => field)

    await this.collectionsApi.execute(
      createSchema(
        collectionId,
        ...fieldsToAdd,
        setDisplayName(formName || collectionId),
        setDisplayField(SUBMISSION_DISPLAY_FIELD),
        markFieldDeleted(TITLE_FIELD),
        setPermissions(PERMISSIONS_BY_PRESET[PRESETS.FORM_INPUTS]),
      ),
    )

    return collectionId
  }

  private _addFieldsToCollection(
    fieldsData: FieldDataForCollectionActions[],
    fieldKeyCallback?: (component: ComponentRef, fieldKey: string) => void,
  ) {
    const fieldKeys = []
    const fieldsCollectionData = filterFieldsToCollection(fieldsData).map(
      ({ crmLabel, collectionFieldKey, collectionFieldType, componentRef }) => {
        const fieldKey = collectionFieldKey
          ? collectionFieldKey
          : createSuffixedName(fieldKeys, _.camelCase(crmLabel), '')
        if (!collectionFieldKey) {
          fieldKeyCallback(componentRef, fieldKey)
        }

        fieldKeys.push(fieldKey)

        return {
          fieldKey,
          fieldConfig: {
            displayName: crmLabel,
            type: collectionFieldType || FieldCollectionType.TEXT,
          },
        }
      },
    )

    return fieldsCollectionData.map(({ fieldKey, fieldConfig }) => addField(fieldKey, fieldConfig))
  }

  @retryOnConcurrentEditingErrors()
  public async addFieldsToCollection(
    collectionId: string,
    fieldsData: FieldDataForCollectionActions[],
    fieldKeyCallback?: (component: ComponentRef, fieldKey: string) => void,
  ) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }
    return this.collectionsApi.execute(
      updateSchema(collectionId, ...this._addFieldsToCollection(fieldsData, fieldKeyCallback)),
    )
  }

  public async isCollectionExists(collectionId): Promise<boolean> {
    if (!collectionId) {
      return false
    }

    if (!this._isWixCodeInstalled()) {
      return false
    }

    try {
      const collection = await this.getSchema(collectionId)
      return !collection.isDeleted
    } catch (err) {
      return false
    }
  }

  public async getCollectionMapById() {
    if (!this._isWixCodeInstalled()) {
      return {}
    }
    return _.keyBy(
      await this.collectionsApi.getAll().catch(() => {
        {
        }
      }),
      'id',
    )
  }

  public getSchema(collectionId: string): Promise<Schema> {
    return this.collectionsApi.get(collectionId)
  }

  @retryOnConcurrentEditingErrors({ absorbException: true })
  public async addFieldToCollection(collectionId, fieldConnectionConfig) {
    const { collectionFieldKey, crmLabel, collectionFieldType } = fieldConnectionConfig

    if (!collectionId || !collectionFieldType) {
      return
    }

    this.fedopsLogger.interactionStarted('add-field-to-collection')
    await this.collectionsApi.execute(
      updateSchema(
        collectionId,
        addField(collectionFieldKey, {
          displayName: crmLabel,
          type: collectionFieldType,
        }),
      ),
    )
    this.fedopsLogger.interactionEnded('add-field-to-collection')
  }

  @retryOnConcurrentEditingErrors()
  public async updateFields(
    collectionId: string,
    fieldsInfo: Array<{ collectionFieldKey: string; crmLabel: string }>,
  ) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        ...fieldsInfo.map(({ collectionFieldKey, crmLabel }) =>
          updateField(collectionFieldKey, {
            displayName: crmLabel,
          }),
        ),
      ),
    )
  }

  @retryOnConcurrentEditingErrors()
  public async updateField(collectionId, fieldKey, displayName) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        updateField(fieldKey, {
          displayName,
        }),
      ),
    )
  }

  private async _addField(collectionId, { fieldKey, displayName, type }) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        addField(fieldKey, {
          displayName,
          type,
        }),
      ),
    )
  }

  @retryOnConcurrentEditingErrors()
  public addPaymentField(collectionId) {
    return this._addField(collectionId, {
      fieldKey: PAYMENT_DISPLAY_FIELD,
      displayName: translations.t(`addForm.submissions.${PAYMENT_DISPLAY_FIELD}`),
      type: FieldCollectionType.TEXT,
    })
  }

  @retryOnConcurrentEditingErrors()
  public async updateFieldType(collectionId: string, collectionFieldKey: string, type: string) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }
    return this.collectionsApi.execute(
      updateSchema(collectionId, updateField(collectionFieldKey, { type })),
    )
  }
}
